// @ts-strict-ignore
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

/**
 * A fake CK dropdown button class that lets us use the dropdown functionality without some of the more cumbersome
 * defaults that CK provides.
 */
export default class ArrowlessDropdownButton extends ButtonView {
  constructor(locale) {
    super(locale);

    (this as any).extendTemplate({
      attributes: {
        'aria-haspopup': true,
      },
    });

    (this as any).delegate('execute').to(this, 'open');

    // Workaround for CKEditor dropdown setup
    (this as any).arrowView = this;
  }

  render() {
    super.render();
  }
}
