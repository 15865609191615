import React from 'react';

export const makeLink = (item: { name: string; link: string }, onLink: (link: string) => void): JSX.Element => (
  <a
    key={`journalLink-${item?.name}`}
    className="force-link-look flexFill anchorButtonBorder cursorPointer mr5 mt1 mb1"
    onClick={() => onLink(item?.link)}>
    {item?.name}
  </a>
);
