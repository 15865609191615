// @ts-strict-ignore
import _ from 'lodash';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Bold, Italic, Strikethrough, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
import {
  Table,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table';
import { PageBreak } from '@ckeditor/ckeditor5-page-break';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { Image, ImageInsert, ImageResize, ImageStyle, ImageToolbar, ImageUpload } from '@ckeditor/ckeditor5-image';
import { Clipboard } from '@ckeditor/ckeditor5-clipboard';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { Font } from '@ckeditor/ckeditor5-font';
import { Pagination } from '@ckeditor/ckeditor5-pagination';
import {
  BasePluginDependencies,
  CustomPlugin,
  CustomPluginSetup,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { SeeqImageUpload, SeeqImageUploadAdapter } from '@/annotation/ckEditorPlugins/plugins/SeeqImageUpload';
import { SeeqImageBorder } from '@/annotation/ckEditorPlugins/plugins/SeeqImageBorder';
import { StripedTables } from '@/annotation/ckEditorPlugins/plugins/StripedTables';
import { DateRangeLabelPlugin } from '@/annotation/ckEditorPlugins/plugins/DateRangeLabel';
import { AssetSelectionLabelPlugin } from '@/annotation/ckEditorPlugins/plugins/AssetSelectionLabel';
import { IframeContentPlugin } from './ckEditorPlugins/plugins/IframeContent';
import i18next from 'i18next';
import { CopyForEmail } from '@/annotation/ckEditorPlugins/plugins/CopyForEmail';
import { RelativeLinks } from '@/annotation/ckEditorPlugins/plugins/RelativeLinks';
import { TemplatePlugin } from '@/annotation/ckEditorPlugins/plugins/Template';
import { InsertContentLink } from '@/annotation/ckEditorPlugins/plugins/InsertContentLink';
import { HeadingOption } from '@/annotation/ckEditorPlugins/types/editor';

const isExternalUrl = (urlToTest) => {
  // consider internal if there's no window (some tests) OR urls start with "/"
  if (!window || !window.location || !urlToTest || urlToTest.startsWith('/')) {
    return false;
  }
  // check domain if we have a full url
  try {
    const urlObj = new URL(urlToTest);
    if (urlObj.hostname === window.location.hostname) {
      return false;
    }
  } catch (error) {
    // the URL could not be extracted - consider external to be on the safe side
    // no-op
  }
  return true;
};

export const DefaultCKConfig = {
  language: 'en',
  plugins: [
    Alignment,
    AutoLink,
    Bold,
    Clipboard,
    Essentials,
    Font,
    Heading,
    Image,
    ImageInsert,
    ImageStyle,
    ImageResize,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    ListStyle,
    PageBreak,
    Pagination,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    Strikethrough,
    Table,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    // custom plugins
    StripedTables,
    PluginDependencies,
    SeeqImageUpload,
    SeeqImageUploadAdapter,
    SeeqImageBorder,
    DateRangeLabelPlugin,
    TemplatePlugin,
    AssetSelectionLabelPlugin,
    IframeContentPlugin,
    CopyForEmail,
    RelativeLinks,
    InsertContentLink,
  ],
  toolbar: [
    'imageInsert',
    '|',
    'link',
    'insertTable',
    'pagebreak',
    '|',
    'heading',
    CopyForEmail.pluginName,
    'fontFamily',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'alignment',
    'outdent',
    'indent',
    'numberedList',
    'bulletedList',
    '|',
    'removeFormat',
    '|',
    'undo',
    'redo',
  ],
  pagination: {},
  licenseKey:
    process.env.NODE_ENV === 'production'
      ? 'ceuGAKdAHFiCbuT8k3sDWpvwEeT6WQL3TI0DoUktkfa8eu8='
      : 'cElUNoM4O0rhgLlYzQomBBgOnvFTMfVH/xXCDJ0aUil/X7c=',
  table: {
    contentToolbar: [
      'tableRow',
      'tableColumn',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
      StripedTables.pluginName,
    ],
  },
  seeqUpload: {
    withCredentials: true,
  },
  image: {
    upload: {
      types: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg+xml'],
    },
    toolbar: [
      'imageStyle:inline',
      'imageStyle:block',
      '|',
      'imageResize',
      '|',
      'imageTextAlternative',
      '|',
      'linkImage',
      '|',
      SeeqImageBorder.pluginName,
    ],
    options: {
      styles: ['inline', 'block'],
    },
  },
  link: {
    defaultProtocol: 'http://',
    decorators: {
      isExternal: {
        mode: 'automatic',
        callback: isExternalUrl,
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
    ],
  },
};

export function createCustomConfig(
  customPlugins: CustomPlugin[],
  basePluginDependencies: BasePluginDependencies,
  customToolbar: string[] = undefined,
) {
  const config = { ...DefaultCKConfig };
  if (customToolbar) config.toolbar = customToolbar;

  addConfigOptionsWithTranslation(config);

  _.chain(customPlugins)
    .map((plugin) => CustomPluginSetup[plugin])
    .forEach((setup) => {
      config.plugins = [...config.plugins, setup.plugin];
      if (setup.config) {
        config[setup.name] = setup.config;
      }
      if (setup.toolbar && !_.includes(config.toolbar, setup.toolbar)) {
        throw new Error(`Plugin ${setup.name} has toolbar options and was not included in the toolbar.`);
      }
    })
    .value();

  config[PluginDependencies.pluginName] = { ...basePluginDependencies };

  return config;
}

function addConfigOptionsWithTranslation(config) {
  const existingOption = _.find(config.heading.options, ['model', 'pre']) as HeadingOption;
  if (existingOption) {
    existingOption.title = i18next.t('REPORT.EDITOR.CODE');
  } else {
    config.heading.options.push({
      model: 'pre',
      title: i18next.t('REPORT.EDITOR.CODE'),
      view: 'pre',
      class: 'codePreformat',
    });
  }
}
