import React from 'react';
import i18next from 'i18next';
import { CONTENT_STATE } from '@/reportEditor/report.constants';
import { setModalName } from '@/reportEditor/reportContent.actions';
import _ from 'lodash';
import { ReactI18NextChild } from 'react-i18next';

interface EditInsertContentLinkProps {
  viewMode?: boolean;
}

export const EditInsertContentLink: React.FunctionComponent<EditInsertContentLinkProps> = ({ viewMode = true }) => {
  return (
    <div
      className={viewMode ? '' : 'cursorPointer pt5 pb5 sq-text-primary'}
      onClick={viewMode ? _.noop : () => setModalName(CONTENT_STATE.WORKBOOK)}>
      <a className="ml10 link-no-underline insert-content-link">
        {i18next.t('REPORT.CONTENT.INSERT_CONTENT') as ReactI18NextChild}
      </a>
    </div>
  );
};
