// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { parseDuration, parseISODate } from '@/datetime/dateTime.utilities';
import { emitComment, onComment } from '@/services/notifier.service';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { canWriteItem } from '@/services/authorization.service';
import { API_TYPES } from '@/main/app.constants';
import { AnnotationInputV1, AnnotationOutputV1, sqAnnotationsApi, sqConditionsApi } from '@/sdk';
import { CapsuleV1 } from '@/sdk/model/CapsuleV1';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import {
  isPresentationWorkbookMode,
  isViewOnlyWorkbookMode,
  validateGuid,
  workbookLoaded,
} from '@/utilities/utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE, PUSH_WORKBOOK } from '@/core/flux.service';
import { createCapsuleLink, createItemLink } from '@/utilities/journalLink.utilities';
import { toggleEditor } from '@/utilities/migration.utilities';
import { ANNOTATION_TYPE, ID_PLACEHOLDER, MAX_NUMBER_OF_ANNOTATE_IDS } from '@/annotation/annotation.constants';
import {
  sqAnnotationStore,
  sqTrendCapsuleSetStore,
  sqTrendCapsuleStore,
  sqTrendStore,
  sqWorkbenchStore,
  sqWorkbookStore,
} from '@/core/core.stores';
import { handleForbidden } from '@/utilities/redaction.utilities';
import {
  addTrendItem,
  fetchChartCapsules,
  fetchTableAndChartCapsules,
  fetchTimebarCapsules,
} from '@/trendData/trend.actions';
import { getDefaultName } from '@/utilities/formula.utilities';
import { setBrowsePanelCollapsed, tabsetChangeTab } from '@/worksheet/worksheet.actions';
import { isWorksheet } from '@/main/routing.utilities';
import { setHtmlCKEditor } from '@/annotation/ckEditor.utilities';

const CAPSULE_SET_PREFIX = 'Annotation Capsules';
let highlightCanceller;

/**
 * Displays a new blank journal entry ready for editing
 */
export function newEntry() {
  exposedForTesting.setId(ID_PLACEHOLDER);
  exposedForTesting.setDocument('');
  exposedForTesting.setIsDiscoverable(false);
}

/**
 * Creates a new annotation, which is effectively a new journal entry with signal, condition, and capsule
 * links automatically inserted, and sets focus to the start of the entry.
 */
export function newAnnotation() {
  let promise = Promise.resolve();
  const links = [];

  links.push('<p><br></p>');

  _.forEach(_.filter(getAllItems({}), 'selected'), (item) => {
    links.push(`<p>${createItemLink(item)}</p>`);
  });

  _.forEach(_.filter(sqTrendCapsuleStore.items, 'selected'), (capsule: any) => {
    links.push(
      `<p>${
        createCapsuleLink({
          conditionId: capsule.isChildOf,
          start: capsule.startTime,
          end: capsule.endTime,
        }).link
      }</p>`,
    );
  });

  if (sqTrendStore.isRegionSelected()) {
    promise = exposedForTesting
      .getUserCapsuleSetInterests([
        {
          start: moment.utc(sqTrendStore.selectedRegion.min).toISOString(),
          end: moment.utc(sqTrendStore.selectedRegion.max).toISOString(),
        },
      ])
      .then((interests) => {
        _.forEach(interests, (interest: any) => {
          links.push(
            `<p>${
              createCapsuleLink({
                conditionId: interest.interestId,
                start: sqTrendStore.selectedRegion.min,
                end: sqTrendStore.selectedRegion.max,
              }).link
            }</p>`,
          );
        });
      });
  }

  promise.then(() => {
    const document = links.join('');
    exposedForTesting.setId(ID_PLACEHOLDER);
    exposedForTesting.setDocument(document, true);
    exposedForTesting.setIsDiscoverable(true);
    exposedForTesting.showJournalTab();
  });
}

/**
 * Displays a journal entry in view mode
 *
 * @param {String} id - The id of the annotation
 * @param {Boolean} [exposeJournalTab = true] - optional argument that allows the default behavior of exposing the
 * journal tab to be overridden.
 */
export function showEntry(id, exposeJournalTab = true) {
  exposedForTesting.setDocument('');
  return exposedForTesting
    .fetchDocument(id)
    .then((result) => {
      if (!result.ckEnabled) {
        return toggleEditor(id).then((document) => ({
          ...result,
          document,
          ckEnabled: true,
        }));
      } else {
        return result;
      }
    })
    .then((result) => {
      exposedForTesting.setId(id);
      exposedForTesting.setIsDiscoverable(result.discoverable);
      exposedForTesting.setIsCkEnabled(result.ckEnabled);
      exposedForTesting.setDocument(result.document);
    })
    .then(() => {
      if (exposeJournalTab) {
        exposedForTesting.showJournalTab();
      }
    });
}

/**
 * Helper function that exposes the browse panel and switches to the Journal tab.
 */
export function showJournalTab() {
  setBrowsePanelCollapsed(false);
  tabsetChangeTab('sidebar', 'annotate');
}

/**
 * Closes a journal entry and displays the overview
 */
export function closeEntry() {
  setExpanded(false);
  exposedForTesting.setId('');
  highlightEntry(sqAnnotationStore.id);
  exposedForTesting.setId();
}

/**
 * Sets the highlightId and then clears it after a short time interval. This is used to highlight the journal entry
 * that had just been open in the journal overview.
 *
 * @param {String} id - a journal entry ID
 */
export function highlightEntry(id) {
  // highlighting should be temporary, so we automatically clear the ID after 3 seconds.
  if (highlightCanceller) {
    clearTimeout(highlightCanceller);
    highlightCanceller = undefined;
  }

  if (!_.isUndefined(id)) {
    flux.dispatch('ANNOTATION_SET_HIGHLIGHT_ID', { id });
    highlightCanceller = setTimeout(function () {
      flux.dispatch('ANNOTATION_SET_HIGHLIGHT_ID', {});
    }, 1500);
  }
}

/**
 * Creates an annotation (aka journal entry) with its name and interests. If a selected region is provided then
 * that is used to create a capsule that also becomes one of the interests. The current workbookId and worksheetId
 * are added as interests to all annotations to make it possible to find all annotations created in a particular
 * workbook and worksheet. If repliesTo is supplied, then the annotation is considered a comment on a journal entry
 * and only the name field must be supplied.
 *
 * @param {Object} args - Object container
 * @param {String} args.name - The name of the journal entry
 * @param {String} [args.id] - The ID of the journal entry if saving an already existing entry
 * @param {String} [args.workbookId] - The ID of the journal entry's workbook
 * @param {String} [args.worksheetId] - The ID of the journal entry's worksheet
 * @param {String} [args.description] - The description of the journal entry
 * @param {String} [args.document] - The journal entry document
 * @param {Object[]} [args.inputInterests] - The journal entry interests
 * @param {String} [args.inputInterests[].interestId] - Id of item being annotated
 * @param {String} [args.inputInterests[].detailId] - Id of item inside a set, such as a capsule, being annotated
 * @param {Object[]} [args.ranges] - The start/end pairs of interest. A new interest will be added for each range
 * @param {String} [args.repliesTo] - The ID of the journal entry that this one replies to (only used by comments)
 * @param {Boolean} [args.discoverable] - the annotation's discoverable setting. Used by the frontend to determine
 *   if the links in a journal entry should be parsed and set as actual backend annotation interests.
 * @return {Promise} Promise that resolves with the new annotation
 */
export function save(args) {
  const id = args.id;
  const name = args.name;
  const description = args.description;
  const document = args.document;
  let inputInterests = args.inputInterests;
  let ranges = args.ranges;
  const repliesTo = args.repliesTo;
  const discoverable = args.discoverable;

  inputInterests = inputInterests || [];
  ranges = _.map(ranges, function (range: any) {
    return {
      start: moment.utc(range.start).toISOString(),
      end: moment.utc(range.end).toISOString(),
    };
  });

  const cancellationGroup = `annotationSave-${id}`;
  const cancelOnServer = true;

  const func = validateGuid(id)
    ? (body) => sqAnnotationsApi.updateAnnotation(body, { id }, { cancellationGroup, cancelOnServer })
    : (body) => sqAnnotationsApi.createAnnotation(body);

  return cancelGroup(cancellationGroup, cancelOnServer)
    .then(() => getUserCapsuleSetInterests(ranges))
    .then(function (additionalInterests) {
      const interests = (
        _.chain(inputInterests)
          .concat([{ interestId: args.workbookId || sqWorkbenchStore.stateParams.workbookId }])
          .concat([{ interestId: args.worksheetId || sqWorkbenchStore.stateParams.worksheetId }])
          .concat(additionalInterests) as any
      ) // See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/6586
        .uniqWith(_.isEqual)
        .value();
      return func(
        _.omitBy(
          {
            name,
            description,
            document,
            interests,
            repliesTo,
            discoverable,
            type: ANNOTATION_TYPE.JOURNAL,
          },
          _.isNil,
        ) as AnnotationInputV1,
      ).then(({ data: annotation }) => {
        flux.dispatch('ANNOTATION_SET_ANNOTATION', { id, annotation }, PUSH_WORKBOOK);
        emitComment(_.chain(interests).map('interestId').uniq().value());
        flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES');

        return annotation;
      });
    });
}

/**
 * Calls getCapsules with a start and end time that is large enough to include all of the supplied ranges
 *
 * @param  ranges - list of start/end pairs
 * @param  conditionId - Guid of the capsule series to request
 * @return {Promise} - resolves with the capsules
 */
export function getCapsulesOverRanges(ranges: { start: string; end: string }[], conditionId: string) {
  return sqConditionsApi
    .getCapsules({
      id: conditionId,
      start: _.minBy(ranges, 'start').start,
      end: _.maxBy(ranges, 'end').end,
    })
    .then(({ data: { capsules } }) => {
      // This removes minor date formatting issues between moment and appserver. Namely corrects
      // "2016-10-31T08:25:00Z" to "2016-10-31T08:25:00.000Z
      capsules.forEach((capsule) => {
        capsule.end = moment.utc(capsule.end).toISOString();
        capsule.start = moment.utc(capsule.start).toISOString();
      });

      return capsules;
    });
}

/**
 * Finds the comment capsule series when needed and then creates or finds the capsules that represent them. Then
 * return the list of interests for the capsules matching the ranges provided
 *
 * @param  ranges - list of start/end pairs
 * @return {Promise} - resolves with the interests that should be added to the annotation
 */
export function getUserCapsuleSetInterests(ranges: { start: string; end: string }[]) {
  // If the analysis is read only, then don't add selected ranges because the user does not have permission to
  // create new capsule(s) on the Annotation Capsules condition to represent the selected region.
  if (_.isEmpty(ranges) || !canWriteItem(sqWorkbookStore)) {
    return Promise.resolve([]);
  }

  return findOrCreateUserCapsuleSet().then((conditionId: string) => {
    return getCapsulesOverRanges(ranges, conditionId)
      .then((existingCapsules: CapsuleV1[]) => {
        // Because lodash propagates undefined, the undefined detail ids were the ones we couldn't find
        const newRanges = _.reject(ranges, _.partial(_.find, existingCapsules));

        return _.isEmpty(newRanges)
          ? existingCapsules
          : _.chain(newRanges as CapsuleV1[])
              .reduce((result, range: any) => {
                const milliseconds = parseISODate(range.end).diff(parseISODate(range.start));
                return milliseconds > result ? milliseconds : result;
              }, 0)
              .thru(
                _.partial(
                  adjustCapsuleSetMaxDuration,
                  conditionId,
                  _.get(sqAnnotationStore, 'annotationCapsuleSet.maximumDuration', 0),
                ),
              )
              .value()
              .then(() =>
                sqConditionsApi
                  .addCapsules({ capsules: newRanges }, { id: conditionId })
                  .then(_.partial(getCapsulesOverRanges, ranges, conditionId))
                  .then((capsules) => {
                    fetchTableAndChartCapsules();
                    fetchChartCapsules();
                    fetchTimebarCapsules(conditionId);
                    return capsules;
                  }),
              );
      })
      .then((capsules) => {
        const interests = _.map(ranges, (range) => ({
          interestId: conditionId,
          detailId: _.get(_.find(capsules, range), 'id'),
        }));

        if (!_.every(interests, 'detailId')) {
          // if the second call to getCapsulesOverRanges doesn't return all the capsules that were created,
          // then _.find wont find all the ranges in `capsules` and we will end up with undefined detailIds
          return Promise.reject('Could not add a comment capsule as a new interest');
        } else {
          return interests;
        }
      });
  });
}

/**
 * Increases the maximumDuration property if it is smaller than a specified interval.
 *
 * @param  {String} id - ID of the capsule set to update
 * @param  {Number} currentDuration - Current max duration for the capsule set, in milliseconds
 * @param  {Number} duration - Duration to test, in milliseconds
 * @return {Promise} Promise that resolves when any property changes have been saved
 */
export function adjustCapsuleSetMaxDuration(id, currentDuration, duration) {
  if (duration <= currentDuration) {
    return Promise.resolve();
  }

  return sqItemsApi.setProperty({ value: `${duration} ms` }, { id, propertyName: 'Maximum Duration' }).then(() => {
    dispatchAnnotationCapsuleSet({ id, maximumDuration: duration });
  });
}

/**
 * Deletes the annotation from the backend and sets mode to RESULTS.
 * NOTE: User-created capsules are not removed if it annotates a time range because backend does not support that.
 *
 * @param {String} id - An annotation id
 * @returns {Promise} Promise that resolves when the annotation has been deleted
 */
export function deleteFn(id) {
  return sqAnnotationsApi.archiveAnnotation({ id }).then(() => {
    if (id === sqAnnotationStore.id) {
      exposedForTesting.setId();
    }

    exposedForTesting.fetchAnnotations();
    emitComment([id]);
  });
}

/**
 * Sets the annotation ID
 *
 * @param {String} [id] - the ID
 */
export function setId(id?) {
  flux.dispatch('ANNOTATION_SET_ID', { id }, PUSH_WORKBOOK);
}

/**
 * Sets the annotation document (which is a string containing sanitized html) in the editor and the store.
 * @param document - the document
 * @param forceUpdate - If the editor supports it, the editor will trigger an update when the document is set
 * @param setCkDocument - If true, sets the internal CKEditor document as well
 */
export function setDocument(document: string, forceUpdate = false, setCkDocument = true) {
  flux.dispatch('ANNOTATION_SET_DOCUMENT', { document }, PUSH_WORKBOOK);
  if (setCkDocument) {
    setHtmlCKEditor(document, 'journalEditor', forceUpdate);
  }
}

/**
 * Sets the isDiscoverable flag, indicating that the Journal entry is being used to annotate something. If
 * isDiscoverable is true, then Journal links in the document will be analyzed and supplied to the /annotations
 * endpoint as the interests and ranges of the annotation.
 *
 * @param {Boolean} isDiscoverable - true if discoverable, false otherwise.
 */
export function setIsDiscoverable(isDiscoverable: boolean) {
  flux.dispatch('ANNOTATION_SET_IS_DISCOVERABLE', { isDiscoverable }, PUSH_WORKBOOK);
}

/**
 * Sets the annotation isExpanded flag, which indicates if the journal editor should be displayed in the
 * expanded (i.e. resizeable) view or not.
 *
 * @param expand - true if expanded, false otherwise.
 */
export function setExpanded(expand: boolean) {
  flux.dispatch('ANNOTATION_SET_IS_EXPANDED', { expand }, PUSH_WORKBOOK);
}

/**
 * Sets the width of the expanded view
 *
 * @param {Number} width - the width
 */
export function setWidth(width: number) {
  flux.dispatch('ANNOTATION_SET_WIDTH', { width }, PUSH_WORKBOOK);
}

/**
 * Sets the height of the expanded view
 *
 * @param {Number} height - the height
 */
export function setHeight(height: number) {
  flux.dispatch('ANNOTATION_SET_HEIGHT', { height }, PUSH_WORKBOOK);
}

/**
 * Sets the isCommentsExpanded flag, which indicates if the comments area of the journal editor should be
 * displayed as expanded or not.
 *
 * @param {Boolean} expand - true if expanded, false otherwise.
 */
export function setCommentsExpanded(expand: boolean) {
  flux.dispatch('ANNOTATION_SET_IS_COMMENTS_EXPANDED', { expand }, PUSH_WORKBOOK);
}

/**
 * Sets the isAnnotatesExpanded flag, which indicates if the annotations area of the journal editor should be
 * displayed as expanded or not.
 *
 * @param {Boolean} expand - true if expanded, false otherwise.
 */
export function setAnnotatesExpanded(expand: boolean) {
  flux.dispatch('ANNOTATION_SET_IS_ANNOTATES_EXPANDED', { expand }, PUSH_WORKBOOK);
}

/**
 * Fetches the annotations that annotate the specified items and dispatches them.
 *
 * @param [workbookId = sqWorkbenchStore.stateParams.workbookId] - optional workbookId. Supplied when worksheet is
 * initially loaded
 *   by router.
 * @param [worksheetId = sqWorkbenchStore.stateParams.worksheetId] - optional worksheetId. Supplied when worksheet is
 *   initially loaded by router.
 *
 * @returns {Promise<AnnotationOutputV1[]>} Promise that is resolved when the annotations have been fetched
 */
export async function fetchAnnotations(
  workbookId = sqWorkbenchStore.stateParams.workbookId,
  worksheetId = sqWorkbenchStore.stateParams.worksheetId,
): Promise<AnnotationOutputV1[]> {
  // workbookId and worksheetId should always be populated, but may sometimes end up being empty if
  // sqWorkbenchStore.stateParams is not fully initialized. This can happen when reloading a page. This safeguard
  // prevents a second annotation from being created in the case where workbookId and worksheetId are not populated.
  if (!workbookLoaded() || isPresentationWorkbookMode() || !workbookId || !worksheetId) {
    return Promise.resolve([]);
  }

  try {
    const allAnnotatePromises = _.chain(exposedForTesting.getAnnotates(workbookId, worksheetId))
      .compact()
      .uniq()
      .chunk(MAX_NUMBER_OF_ANNOTATE_IDS)
      .map((annotates) => sqAnnotationsApi.getAnnotations({ annotates, type: ANNOTATION_TYPE.JOURNAL, limit: 1000 }))
      .value();
    const result = await Promise.all(allAnnotatePromises);
    const annotations = _.chain(result)
      .flatMap(({ data }) => data.items)
      .uniqBy('id')
      .filter((annotation) => {
        return (
          annotation.discoverable ||
          _.intersection(_.map(annotation.interests, 'item.id'), _.map(sqWorkbookStore.worksheets, 'worksheetId'))
            .length > 0
        );
      })
      .value();

    flux.dispatch('ANNOTATION_SET_ANNOTATIONS', { annotations }, PUSH_IGNORE);
    flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES');

    return annotations;
  } catch (response) {
    return handleForbidden(response, []);
  }
}

/**
 * If no annotation is in the store and none exist for the current worksheet, then open a new annotation and
 * make it display in edit mode on the Journal tab. If there is a single existing annotation, then
 * automatically display it on the Journal tab. If there are more than one journal entries for the worksheet,
 * then close the entry and allow the journal overview to display.
 *
 * @param {any} [worksheetId = sqWorkbenchStore.stateParams.worksheetId] - the worksheet ID
 * @returns {Promise} a promise that resolves when the display operation is complete
 */
export function displayNewOrExisting(worksheetId = sqWorkbenchStore.stateParams.worksheetId) {
  const worksheetJournalEntries = sqAnnotationStore.findJournalEntries(sqAnnotationStore.annotations, worksheetId);
  if (worksheetJournalEntries.length === 0) {
    // Only display a new entry in edit mode if the user has permission to do so
    if (sqWorkbookStore.effectivePermissions.write) {
      exposedForTesting.newEntry();
    }
  } else if (worksheetJournalEntries.length === 1) {
    const id = _.get(worksheetJournalEntries, '0.id');
    return exposedForTesting.showEntry(id, false);
  } else {
    exposedForTesting.closeEntry();
  }

  return Promise.resolve();
}

/**
 * Fetches the journal document
 *
 * @param {String} id - the ID
 *
 * @returns {Promise} a promise that resolves when the document has been retrieved
 */
export function fetchDocument(id: string): Promise<AnnotationOutputV1> {
  if (!validateGuid(id)) {
    return Promise.resolve({} as AnnotationOutputV1);
  }

  return sqAnnotationsApi
    .getAnnotation({ id })
    .then(({ data }) => data)
    .catch(() => ({} as AnnotationOutputV1)); // Can happen if annotation was deleted
}

/**
 * Finds the capsule set that is used to store user-created capsules from when a user annotates a selected time
 * region. If it does not exist it is created, and the resulting ID and maximumDuration are stored in
 * sqAnnotationStore.annotationCapsuleSet.
 *
 * @returns {Promise} Promise that is resolved with the ID of the user capsule set
 */
export function findOrCreateUserCapsuleSet() {
  let promise;

  if (sqAnnotationStore.annotationCapsuleSet) {
    promise = Promise.resolve(sqAnnotationStore.annotationCapsuleSet.id);
  } else {
    promise = sqItemsApi
      .searchItems({
        filters: [`name==/${CAPSULE_SET_PREFIX}.*/`],
        scope: sqWorkbenchStore.stateParams.workbookId
          ? [sqWorkbenchStore.stateParams.workbookId.toString()]
          : undefined,
        types: [API_TYPES.STORED_CONDITION],
        offset: 0,
        limit: 1,
      })
      .then(({ data }) => {
        if (!_.isEmpty(data.items)) {
          // Fetch the metadata, so that we know the current maximumDuration and can increase it as needed
          return sqConditionsApi
            .getCondition({ id: _.head(data.items).id })
            .then(({ data }) => dispatchAnnotationCapsuleSet(data))
            .then(_.property('id'));
        }

        // Note: a capsule series is required to have a maximum duration, so we start from a relatively short
        // 1-day duration. The duration is increased as needed each time a new comment is added to this capsule
        // set, such that it is always at least as large as the duration of the longest comment.
        return getDefaultName(CAPSULE_SET_PREFIX, sqWorkbenchStore.stateParams.workbookId)
          .then((name: string) =>
            sqConditionsApi.createCondition({
              name,
              scopedTo: sqWorkbenchStore.stateParams.workbookId,
              maximumDuration: '1 day',
            }),
          )
          .then(({ data }) => dispatchAnnotationCapsuleSet(data))
          .then(_.property('id'));
      });
  }

  return promise.then((foundOrCreatedCapsuleSetId) =>
    sqTrendCapsuleSetStore.findItem(foundOrCreatedCapsuleSetId)
      ? Promise.resolve(foundOrCreatedCapsuleSetId)
      : addTrendItem({
          id: foundOrCreatedCapsuleSetId,
          type: API_TYPES.STORED_CONDITION,
        }).then(() => foundOrCreatedCapsuleSetId),
  );
}

/**
 * Gets the ids of all of the items that we want to get annotations for
 *
 * @param [workbookId] - optional workbookId
 * @param [worksheetId] - optional worksheetId
 *
 * @return {String[]} list of ids that can be passed to the Annotations endpoint
 */
export function getAnnotates(
  workbookId = sqWorkbenchStore.stateParams.workbookId,
  worksheetId = sqWorkbenchStore.stateParams.worksheetId,
): string[] {
  return _.chain(getAllItems({}))
    .map('id')
    .concat([workbookId])
    .concat([worksheetId])
    .compact() // Handles the case sqWorkbenchStore.stateParams.workbookId is undefined
    .value();
}

/**
 * Attaches a listener to the Socket that handles updating the comments if they changes
 */
export function setupNotifierForAnnotation() {
  onComment((interests) => {
    let targetInterests;
    if (!isWorksheet() || isViewOnlyWorkbookMode()) {
      return;
    }

    targetInterests = _.chain(exposedForTesting.getAnnotates())
      .concat(_.map(sqAnnotationStore.annotations, 'id'))
      .concat(_.chain(sqAnnotationStore.annotations).flatMap('replies').map('id').value())
      .value();

    if (!_.isEmpty(_.intersection(targetInterests, interests))) {
      exposedForTesting.fetchAnnotations();

      if (interests.indexOf(sqWorkbenchStore.stateParams.worksheetId) >= 0) {
        exposedForTesting.fetchDocument(sqAnnotationStore.id).then((result) => {
          exposedForTesting.setDocument(result.document);
        });
      }
    }
  });
}

/**
 * Dispatches the annotation capsule set information to the store.
 *
 * @param  {Object} capsuleSet - Annotation Capsule Set
 * @param  {String} capsuleSet.id - ID of the item
 * @param  {String|Number} capsuleSet.maximumDuration - The maximum length of a capsule in the capsule set.
 *   If this is a number, it is in milliseconds. If a string, it should have a suffix with the units.
 *
 * @returns {Object} Returns the provided capsuleSet object
 */
export function dispatchAnnotationCapsuleSet(capsuleSet) {
  const milliseconds = _.isFinite(capsuleSet.maximumDuration)
    ? capsuleSet.maximumDuration
    : parseDuration(capsuleSet.maximumDuration).asMilliseconds();

  flux.dispatch(
    'ANNOTATION_SET_CAPSULE_SET',
    {
      id: capsuleSet.id,
      maximumDuration: milliseconds,
    },
    PUSH_IGNORE,
  );

  return capsuleSet;
}

export function setIsCkEnabled(isCkEnabled: boolean) {
  flux.dispatch('ANNOTATION_SET_IS_CK_ENABLED', { isCkEnabled }, PUSH_WORKBOOK);
}

export const exposedForTesting = {
  newEntry,
  newAnnotation,
  showEntry,
  closeEntry,
  save,
  setId,
  setDocument,
  setExpanded,
  setWidth,
  setHeight,
  setCommentsExpanded,
  setAnnotatesExpanded,
  setIsDiscoverable,
  setIsCkEnabled,
  getAnnotates,
  delete: deleteFn,
  fetchAnnotations,
  displayNewOrExisting,
  fetchDocument,
  showJournalTab,
  setupNotifierForAnnotation,
  getUserCapsuleSetInterests, // exposed for test
};
