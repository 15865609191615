// @ts-strict-ignore
/* istanbul ignore file */
import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-link.svg';
import i18next from 'i18next';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { getDefaultContextUrl } from '@/notifications/notifications.utilities';

export const INSERT_WORKSHEET_LINK_COMMAND = 'worksheet_link';

export class WorksheetLink extends Plugin {
  static pluginName = 'WorksheetLink';
  static setup = {
    name: WorksheetLink.pluginName,
    plugin: WorksheetLink,
    toolbar: WorksheetLink.pluginName,
  };

  init() {
    this.editor.commands.add(INSERT_WORKSHEET_LINK_COMMAND, new WorksheetLinkCommand(this.editor));
    this.defineToolbarButton();
  }

  defineToolbarButton() {
    const editor = (this as any).editor;

    editor.ui.componentFactory.add(WorksheetLink.pluginName, (locale) => {
      const command = editor.commands.get(INSERT_WORKSHEET_LINK_COMMAND);
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: i18next.t('NOTIFICATIONS.MODAL.ADD_WORKSHEET_LINK'),
        icon,
        tooltip: true,
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => editor.execute(INSERT_WORKSHEET_LINK_COMMAND));

      return buttonView;
    });
  }
}

export class WorksheetLinkCommand extends Command {
  execute() {
    const stateParams = sqWorkbenchStore.stateParams;
    const name = `${sqWorkbookStore.name} - ${sqWorkbookStore.getWorksheetName(stateParams.worksheetId)}`;
    const link = `<a href="${getDefaultContextUrl()}">${name}</a>`;

    const editor = (this as any).editor;
    if (editor.model.document.selection.isCollapsed) {
      const viewFragment = editor.data.processor.toView(link);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(modelFragment);
    } else {
      editor.execute('link', link.match('href="(.*)">')[1]);
    }
  }
}
