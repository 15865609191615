// @ts-strict-ignore
import React from 'react';
import { ASSET_SELECTION_LABEL_ATTRIBUTES, BasePluginDependencies } from '../CKEditorPlugins.constants';
import InsertablePlugin from '@/annotation/ckEditorPlugins/plugins/InsertablePlugin';
import { EditAssetSelectionLabel } from '@/annotation/ckEditorPlugins/components/EditAssetSelectionLabel.molecule';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { Command } from '@ckeditor/ckeditor5-core';

const SCHEMA_MODEL_NAME = 'asset_label';
const UNIQUE_CLASS = 'dateRangeLabel';
const CLASSES = `customCkComponent ${UNIQUE_CLASS}`;
const DATA_ASSET_SELECTION_ID = ASSET_SELECTION_LABEL_ATTRIBUTES.ASSET_SELECTION_ID;
const DATA_ASSET_SELECTION_DEPTH_LEVEL = ASSET_SELECTION_LABEL_ATTRIBUTES.ASSET_SELECTION_DEPTH_LEVEL;

/**
 * Plugin that enables the use of AssetSelectionLabel components inside the CKEditor.
 * This plugin adds the asset_label command to CKEditor
 */
export class AssetSelectionLabelPlugin extends InsertablePlugin {
  getSchemaModelName(): string {
    return SCHEMA_MODEL_NAME;
  }

  getSchemaAttributes(): string[] {
    return [DATA_ASSET_SELECTION_ID, DATA_ASSET_SELECTION_DEPTH_LEVEL];
  }

  getDataElementName(): string {
    return 'span';
  }

  getDataClasses(): string {
    return CLASSES;
  }

  getReactElement(modelElement: ModelElement): React.ReactElement {
    const editorConfiguration = this.editor.config;
    const deps: BasePluginDependencies = editorConfiguration.get(PluginDependencies.pluginName);
    const assetSelectionId = modelElement.getAttribute(DATA_ASSET_SELECTION_ID);
    const assetSelectionDepthLevel = modelElement.getAttribute(DATA_ASSET_SELECTION_DEPTH_LEVEL);

    return (
      <EditAssetSelectionLabel
        viewMode={!deps.canModify}
        updateId={this.updateAttribute(modelElement, DATA_ASSET_SELECTION_ID)}
        updateDepthLevel={this.updateAttribute(modelElement, DATA_ASSET_SELECTION_DEPTH_LEVEL)}
        pathLevel={assetSelectionDepthLevel}
        assetSelectionId={assetSelectionId}
      />
    );
  }

  postInit(): void {
    this.editor.commands.add(SCHEMA_MODEL_NAME, new AssetLabelCommand(this.editor));
  }

  getUniqueClass(): string {
    return UNIQUE_CLASS;
  }
}

export class AssetLabelCommand extends Command {
  execute() {
    const editor = this.editor;
    editor.model.change((writer) => {
      if (editor.model.document.selection.getSelectedElement()) {
        writer.setSelection(editor.model.document.selection.getSelectedElement(), 'after');
      }
      // Create a <placeholder> elment with the "name" attribute (and all the selection attributes)...
      const element = writer.createElement(SCHEMA_MODEL_NAME, []);

      // ... and insert it into the document.
      editor.model.insertObject(element);

      // Put the selection on the inserted element.
      writer.setSelection(element, 'on');
      // we need to focus back on the editor after a short delay
      editor.editing.view.focus();
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    if (selection.focus.parent) {
      const isAllowed = model.schema.checkChild(selection.focus.parent, SCHEMA_MODEL_NAME);
      this.isEnabled = isAllowed;
    }
  }
}
