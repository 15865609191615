// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AssetSelectionLabelIF {
  onClick?: (e) => void;
  targetRef?: any;
  assetName: string;
}

export const AssetSelectionLabel: React.FunctionComponent<AssetSelectionLabelIF> = ({
  onClick = null,
  targetRef = null,
  assetName,
}) => {
  const { t } = useTranslation();
  const loadingPlaceholder = t('REPORT.DATE_RANGE_LABEL.LOADING');
  const [content, setContent] = useState(loadingPlaceholder);

  useEffect(() => {
    if (assetName) {
      setContent(assetName);
    } else if (assetName === '') {
      setContent(t('REPORT.DATE_RANGE_LABEL.INVALID_ASSET_SELECTION'));
    }
  }, [assetName]);

  return (
    <span ref={targetRef} onClick={onClick} data-testid="assetSelectionLabelText">
      {content}
    </span>
  );
};
