import _ from 'lodash';
import { INTERACTIVE_CONTENT_WORKSTEP_VERSION } from '@/reportEditor/report.constants';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { ChartViewContentProperties } from '@/reportEditor/ChartViewContent.molecule';
import { TableBuilderPropsOnlyIF } from '@/tableBuilder/TableBuilderPropsOnly.organism';

/**
 * Upgrade the state of an interactive content property map from the specified version to the latest version. Runs the
 * map through a series of transform functions, in order, from the map's version to the specified version.
 *
 * @param properties - The content properties
 * @param fromVersion - The version number from when the blob was created
 * @param [toVersion] - The version number up to which the blob will be migrated. Useful only for testing.
 * @returns The transformed blob.
 */
export function applyContentUpgrade(
  properties: any,
  fromVersion: number,
  toVersion = INTERACTIVE_CONTENT_WORKSTEP_VERSION,
): any {
  const upgradedProperties = _.reduce(
    _.range(fromVersion, toVersion, 1),
    (newProperties, newVersion) => {
      const upgraderName = `upgrade${newVersion}`;
      const upgrader = upgraders[upgraderName];
      // Subtle difference from workstepUpgrader::apply, we don't require upgrades for every workstep version
      return upgrader ? upgrader(newProperties) : newProperties;
    },
    properties,
  );
  upgradedProperties.version = INTERACTIVE_CONTENT_WORKSTEP_VERSION;
  return upgradedProperties;
}

/**
 * Assigns lanes to conditions and shift other item lanes.
 * Modified from upgrade workstepUpgrade::upgrade50
 *
 * @param untypedProperties
 */
function upgrade50(untypedProperties: any) {
  if (untypedProperties.visualization !== Visualization.TREND) {
    return untypedProperties;
  }

  const properties = untypedProperties as ChartViewContentProperties;
  const conditionItems = _.sortBy(properties?.data?.sqTrendCapsuleSetStoreData?.items ?? [], 'conditionLane');

  if (_.some(conditionItems, (condition) => condition.lane)) {
    // Conditions already have assigned lane property, so we can leave early.
    return untypedProperties;
  }

  let conditionsEndLane = 0;
  const conditionIdToLane = _.map(conditionItems, (item, index) => {
    const newLane = +index + 1;
    item.lane = newLane;
    conditionsEndLane = newLane;
    if (_.has(item, 'conditionLane')) {
      delete item.conditionLane;
    }
    return { id: item.id, lane: newLane };
  });

  if (conditionsEndLane === 0) {
    return properties;
  }

  (properties.data.sqTrendCapsuleSetStoreData as any).items = conditionItems;

  properties.data.items = _.map(properties.data.items, (item) => {
    // Gets certain and uncertain capsules
    if (item.capsuleSetId) {
      const conditionId = item.capsuleSetId.includes('_')
        ? item.capsuleSetId.substring(0, item.capsuleSetId.indexOf('_'))
        : item.capsuleSetId;
      item.lane = _.find(conditionIdToLane, { id: conditionId })?.lane;
    } else if (_.isInteger(item.lane)) {
      item.lane += conditionsEndLane;
    }
    return item;
  });

  properties.data.lanes = _.chain(properties.data.items)
    .map((item) => item.lane)
    .uniq()
    .value()
    .sort();

  return properties;
}

const TABLE_CHART_COLUMN_PROPS = ['columns', 'categoryColumns'] as const;

/**
 * Changes table builder chart columns to reference the column based on name instead of index.
 * Modified from upgrade workstepUpgrade::upgrade51
 *
 * @param untypedProperties
 */
function upgrade51(untypedProperties: any): any {
  if (untypedProperties.visualization !== Visualization.TABLE) {
    return untypedProperties;
  }
  const properties = untypedProperties as TableBuilderPropsOnlyIF;
  if (properties.chartViewSettings) {
    _.forEach(TABLE_CHART_COLUMN_PROPS, (setting) => {
      if (_.isArray(properties.chartViewSettings[setting])) {
        _.forEach(properties.chartViewSettings[setting], (columnIndex, index) => {
          if (_.isNumber(columnIndex) && properties.simpleColumns[columnIndex]) {
            properties.chartViewSettings[setting].splice(index, 1, properties.simpleColumns[columnIndex].key);
          }
        });
      }
    });
    // Old version of rows was indices that pointed to items in the details pane. Since the details pane cannot be
    // reconstructed the setting is emptied.
    if (_.every(properties.chartViewSettings.rows, _.isNumber)) {
      properties.chartViewSettings.rows = [];
    }

    _.forEach(properties.simpleTableData, (data) => {
      _.forEach(data.cells, (cell) => {
        if (!cell.rawValue) {
          const maybeNumber = parseFloat(cell.value);
          cell.rawValue = _.isNaN(maybeNumber) ? cell.value : maybeNumber;
        }
      });
    });
  }

  return properties;
}

const upgraders: Record<string, (properties: any) => any> = {
  upgrade50,
  upgrade51,
};
