import React from 'react';
import classNames from 'classnames';
import { BLANK_IMAGE } from '@/annotation/ckEditorPlugins/components/RefreshingContent.molecule';
import { ContentVisualizationProps } from '@/annotation/ckEditorPlugins/components/content.utilities';
import { getAxiosInstance } from '@/requests/axios.utilities';
import { CONTENT_LOADING_CLASS, IMAGE_BORDER_CLASS } from '@/reportEditor/report.constants';
import { contentError } from '@/annotation/reportContent.utilities';

export const ImageContent: React.FunctionComponent<ContentVisualizationProps> = ({
  contentId,
  noMargin,
  border,
  errorClass,
  extraClassNames,
  style,
  loading,
  target,
  src,
  onLoad,
  onError,
  onMeasurementsReady,
  showMenu,
  error,
}) => {
  return (
    <img
      data-testid={`content-${contentId}`}
      data-seeq-content={contentId}
      data-visualization="image"
      style={style}
      ref={target}
      className={classNames(noMargin ? '' : 'p2', border ? IMAGE_BORDER_CLASS : '', extraClassNames, errorClass ?? '', {
        [CONTENT_LOADING_CLASS.SPINNER]: loading && !errorClass,
        [CONTENT_LOADING_CLASS.LOADED]: !loading && !errorClass,
      })}
      title={error}
      src={errorClass ? BLANK_IMAGE : src}
      onLoad={(ref) => {
        // Initial load will return a 1x1 empty image. Subsequent loads shouldn't.
        if (ref.currentTarget.naturalWidth !== 1 && ref.currentTarget.naturalHeight !== 1) {
          onLoad();
          // if it's an image let it take its own size
          onMeasurementsReady(0, 0);
        }
      }}
      onError={(e) => {
        const image = e.currentTarget;
        // re-try the request and get the error message to show more information in a tooltip
        getAxiosInstance()
          .get(e.currentTarget.src)
          .catch((error) => {
            contentError(contentId, error, error.status);
          });
        image.className = 'contentLoadError';
        image.src = BLANK_IMAGE;
      }}
      onClick={showMenu}
    />
  );
};
