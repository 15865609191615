// @ts-strict-ignore
import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-copy.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import {
  convertContentHslToHex,
  convertImagesSrc,
  convertStyleToInline,
  getCKEditorCssStyle,
} from '@/core/html.utilities';
import { infoToast, warnToast } from '@/utilities/toast.utilities';
import i18next from 'i18next';

export const COMMAND_NAME = 'copy_for_external';

export class CopyForEmail extends Plugin {
  static pluginName = 'CopyForEmail';
  static setup = {
    name: CopyForEmail.pluginName,
    plugin: CopyForEmail,
    toolbar: CopyForEmail.pluginName,
  };
  shouldCopyForEmail = false;
  deps: BasePluginDependencies;

  init() {
    this.deps = this.editor.config.get(PluginDependencies.pluginName);
    this.defineToolbarButton();
    this.defineClipboardHandlers();

    this.editor.commands.add(COMMAND_NAME, new CopyForEmailCommand(this.editor));
  }

  defineToolbarButton() {
    const editor = this.editor;

    editor.ui.componentFactory.add(CopyForEmail.pluginName, (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: i18next.t('REPORT.EDITOR.COPY_FOR_EMAIL'),
        icon,
        tooltip: true,
        class: 'copyForExternalPaste',
      });
      view.on('execute', () => {
        this.shouldCopyForEmail = true;
        editor.execute(COMMAND_NAME);
      });
      return view;
    });
  }

  defineClipboardHandlers() {
    this.editor.editing.view.document.on(
      'clipboardOutput',
      (evt, data) => {
        const clipboardData = data.dataTransfer.getData('text/html');
        const isForEmail = this.shouldCopyForEmail;
        // convert images src to base64 even if it's not for email
        let processedData = convertImagesSrc(clipboardData);

        if (!this.editor.isReadOnly && isForEmail) {
          const contentPreJuice = `<div class="ck-content" data-notallowpaste="true">${processedData}</div>`;
          processedData = convertContentHslToHex(convertStyleToInline(contentPreJuice, getCKEditorCssStyle()));
        }

        data.dataTransfer.setData('text/html', processedData);
        if (isForEmail) {
          infoToast({ messageKey: 'REPORT.EDITOR.COPY_FOR_EMAIL_SUCCESS' });
          evt.stop();
        }
        this.shouldCopyForEmail = false;
      },
      { priority: 'lowest' },
    );

    this.editor.editing.view.document.on(
      'clipboardInput',
      (evt, data) => {
        if (this.editor.isReadOnly) {
          return;
        }
        const dataTransfer = data.dataTransfer;
        const content = dataTransfer.getData('text/html');
        if (content.indexOf('data-notallowpaste="true"') !== -1) {
          warnToast({ messageKey: 'REPORT.EDITOR.NO_PASTE_FOR_EMAIL' });
          evt.stop();
        }
      },
      { priority: 'highest' },
    );
  }
}

export class CopyForEmailCommand extends Command {
  execute() {
    const editor = this.editor;
    const firstPosition = editor.model.document.selection.getFirstPosition();
    const lastPosition = editor.model.document.selection.getLastPosition();
    if (firstPosition.compareWith(lastPosition) === 'same') {
      editor.execute('selectAll');
    }
    window.document.execCommand('copy');
  }
}
