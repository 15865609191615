// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { DateRangeLabel } from './DateRangeLabel.molecule';
import { Form, Overlay, Popover } from 'react-bootstrap';
import { useFlux } from '@/core/hooks/useFlux.hook';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS, DateFormatSelector } from '@/core/DateFormatSelector.molecule';
import { copyDateRangeForPendingContent } from '@/annotation/reportContent.utilities';
import { fetchDateRange } from '@/reportEditor/report.actions';
import { Select } from '@seeqdev/qomponents';
import { sqConditionsApi } from '@/sdk';
import moment from 'moment';
import { HeaderOptionsSelector } from '@/core/HeaderOptionsSelector.molecule';
import { calculateOptimalTooltipPosition } from '@/core/calculateOptimalTooltipPosition';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { base64guid } from '@/utilities/utilities';
import { fetchCapsuleProperties } from '@/utilities/investigateHelper.utilities';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';

interface EditDateRangeLabelProps {
  dateRangeId: string;
  dateRangeContent: string;
  dateRangeFormat: string;
  updateId?: (id: string) => void;
  updateContent?: (content: string) => void;
  updateFormat?: (format: string) => void;
  viewMode?: boolean;
}

export const CONTENT_TYPES = {
  START: 'start_only',
  END: 'end_only',
  START_END: 'start_end',
};

export const DEFAULT_RANGE_START = -1;

export const EditDateRangeLabel: React.FunctionComponent<EditDateRangeLabelProps> = ({
  dateRangeId,
  dateRangeContent,
  dateRangeFormat,
  viewMode = true,
  updateId = () => null,
  updateContent = () => null,
  updateFormat = () => null,
}) => {
  const { t } = useTranslation();
  const popoverRef = useRef(null);
  const [placement, setPlacement] = useState('right' as Placement);
  const [showMenu, setShowMenu] = useState(false);
  const [conditionProperties, setConditionProperties] = useState(null);
  const [capsuleData, setCapsuleData] = useState(null);
  const target = useRef(null);

  const { dateRangesNotArchived } = useFlux(sqReportStore);
  const selectedDateRangeId = dateRangeId;
  const format = dateRangeFormat || _.head(DATE_FORMATS);
  const contentSelected = dateRangeContent || CONTENT_TYPES.START;
  const dateRange = _.find(dateRangesNotArchived, ['id', selectedDateRangeId], null);
  const dateRangeStart = dateRange ? dateRange.range.start : DEFAULT_RANGE_START;
  const dateRangeEnd = dateRange ? dateRange.range.end : DEFAULT_RANGE_START;
  const ownId = useRef(null);

  const fetchCapsule = (id, start, end) =>
    sqConditionsApi
      .getCapsules({
        id,
        start: moment.utc(start).toISOString(),
        end: moment.utc(end).toISOString(),
      })
      .then((result) => {
        const capsule = result.data.capsules[0];
        if (capsule?.start || capsule?.end) {
          setCapsuleData(capsule);
        }
      });

  const fetchConditionProperties = (id) => fetchCapsuleProperties(id).then(setConditionProperties);

  useEffect(() => {
    if (dateRange && dateRange.condition.id) {
      // when the date range has changed, fetch the new capsule data and then setup the condition properties
      fetchCapsule(dateRange.condition.id, dateRangeStart, dateRangeEnd).then(() =>
        fetchConditionProperties(dateRange.condition.id),
      );
    } else {
      setCapsuleData(null);
      setConditionProperties(null);
    }
  }, [dateRangeStart, dateRangeEnd]);

  useEffect(() => {
    ownId.current = base64guid();
    if (!selectedDateRangeId && dateRangesNotArchived.length && dateRangesNotArchived[0].id) {
      updateId(dateRangesNotArchived[0].id);
    } else if (!dateRange && selectedDateRangeId) {
      fetchDateRange(selectedDateRangeId, false).then(({ dateRange }) => {
        if (viewMode) {
          let similarDateRange = sqReportStore.findSimilarDateRange(dateRange);
          if (_.isUndefined(similarDateRange)) {
            similarDateRange = sqReportStore.findDateRangeByName(dateRange.name);
          }
          updateId(similarDateRange?.id ?? selectedDateRangeId);
        } else {
          return copyDateRangeForPendingContent(dateRange, Promise).then(updateId);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (popoverRef.current) {
      setPlacement(calculateOptimalTooltipPosition(target.current, popoverRef.current));
    }
  }, [popoverRef?.current]);

  let contentOptions = [
    {
      label: t('REPORT.DATE_RANGE_LABEL.START_TIME'),
      value: CONTENT_TYPES.START,
      id: `content-select-${ownId.current}-1`,
    },
    {
      label: t('REPORT.DATE_RANGE_LABEL.END_TIME'),
      value: CONTENT_TYPES.END,
      id: `content-select-${ownId.current}-2`,
    },
    {
      label: t('REPORT.DATE_RANGE_LABEL.START_END_TIME'),
      value: CONTENT_TYPES.START_END,
      id: `content-select-${ownId.current}-3`,
    },
  ];
  if (conditionProperties && conditionProperties.length > 0) {
    // add the properties to the options
    contentOptions = _.concat(
      contentOptions,
      _.map(conditionProperties, ({ name }, index) => ({
        label: name,
        value: name,
        id: `content-select-custom-${index}`,
      })),
    );
  }

  return (
    <>
      {!viewMode && (
        <Overlay
          transition={false}
          placement={placement}
          target={target.current}
          rootClose={true}
          show={showMenu}
          onHide={() => setShowMenu(false)}>
          <Popover id={`dateRangeMenu-${ownId.current}`} className="dateRangePopover">
            <div ref={popoverRef}>
              <Popover.Title>{t('REPORT.DATE_RANGE_LABEL.CONFIGURATION')}</Popover.Title>
              <Popover.Content className="ml10 mr10">
                <Form.Label>{t('REPORT.DATE_RANGE_LABEL.SELECT_DATE_RANGE')}</Form.Label>
                <Select
                  value={dateRange ? { value: dateRange.id, label: dateRange.name } : null}
                  options={_.map(dateRangesNotArchived, (item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  onChange={(selected: { value: string }) => updateId(selected.value)}
                />
                <HeaderOptionsSelector
                  options={contentOptions}
                  onChange={updateContent}
                  name={`dateRangeContent-${ownId.current}`}
                  title={t('REPORT.DATE_RANGE_LABEL.LABEL_CONTENT')}
                  defaultValue={contentSelected}
                />
                {!_.some(conditionProperties, ['name', dateRangeContent]) && (
                  <DateFormatSelector
                    label={t('REPORT.DATE_RANGE_LABEL.DATE_FORMAT')}
                    predefinedFormatsLabel={t('TABLE_BUILDER.PREDEFINED_FORMATS')}
                    value={format}
                    id={`format-select-${ownId.current}`}
                    onChange={updateFormat}
                    onKeyUp={(event) => updateFormat(event.target.value)}
                    timezone={sqWorksheetStore.timezone.name}
                  />
                )}
              </Popover.Content>
            </div>
          </Popover>
        </Overlay>
      )}
      <DateRangeLabel
        onClick={() => setShowMenu(true)}
        targetRef={target}
        dateRange={dateRange}
        contentToDisplay={contentSelected}
        format={format}
        timezone={sqWorksheetStore.timezone.name}
        capsuleData={capsuleData}
      />
    </>
  );
};
