import React from 'react';
import InsertablePlugin from '@/annotation/ckEditorPlugins/plugins/InsertablePlugin';
import { EditInsertContentLink } from '@/annotation/ckEditorPlugins/components/EditInsertContentLink.molecule';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';

const SCHEMA_MODEL_NAME = 'insert_content_link';
const SPECIFIC_CLASS = 'insertContentLink';
const CLASSES = `customCkComponent ${SPECIFIC_CLASS}`;

export class InsertContentLink extends InsertablePlugin {
  getSchemaModelName(): string {
    return SCHEMA_MODEL_NAME;
  }

  getSchemaAttributes(): undefined {
    return undefined;
  }

  getDataElementName(): string {
    return 'span';
  }

  getReactElement(modelElement: ModelElement): React.ReactElement {
    const deps: BasePluginDependencies = this.editor.config.get(PluginDependencies.pluginName);
    return <EditInsertContentLink viewMode={!deps.canModify} />;
  }

  getUniqueClass(): string {
    return SPECIFIC_CLASS;
  }

  getDataClasses(): string {
    return CLASSES;
  }

  postInit(): void {}
}
