import _ from 'lodash';
import React from 'react';
import { Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { renderElementWithRoot } from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { Root } from 'react-dom/client';

/**
 * Provides helper utilities for managing plugins which inserts elements (e.g. Content) into the editor
 */
abstract class InsertablePlugin extends Plugin {
  private reactComponents: { element: HTMLElement; root: Root }[] = [];

  abstract getSchemaModelName(): string;

  abstract getSchemaAttributes(): string[] | undefined;

  abstract getDataElementName(): string;

  abstract getDataClasses(): string;

  abstract getReactElement(modelElement: ModelElement): React.ReactElement;

  abstract postInit(): void;

  abstract getUniqueClass(): string;

  init() {
    this.defineSchema();
    this.defineConverters();
    this.postInit();

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement: ViewElement) =>
        viewElement.hasClass(this.getUniqueClass()),
      ),
    );
  }

  defineSchema() {
    const schema = this.editor.model.schema;
    schema.register(this.getSchemaModelName(), {
      allowWhere: '$text',
      isInline: true,
      isObject: true,
      allowAttributesOf: '$text',
      allowAttributes: this.getSchemaAttributes(),
    });
  }

  defineConverters() {
    const conversion = this.editor.conversion;

    // converters ((data) view → model)
    conversion.for('upcast').elementToElement({
      view: {
        name: this.getDataElementName(),
        attributes: {
          class: this.getDataClasses(),
          ..._.reduce(
            this.getSchemaAttributes(),
            (acc, attribute) => ({
              ...acc,
              [attribute]: true,
            }),
            {},
          ),
        },
      },
      model: (viewElement: ViewElement, { writer: modelWriter }: { writer: ModelWriter }) => {
        return modelWriter.createElement(this.getSchemaModelName(), {
          ..._.reduce(
            this.getSchemaAttributes(),
            (acc, attribute) => ({
              ...acc,
              [attribute]: viewElement.getAttribute(attribute),
            }),
            {},
          ),
        });
      },
      converterPriority: 'highest',
    });

    // converters (model → data view)
    conversion.for('dataDowncast').elementToElement({
      model: this.getSchemaModelName(),
      view: (modelItem: ModelElement, { writer: viewWriter }: { writer: ViewWriter }) =>
        this.createDataElement(modelItem, viewWriter),
    });

    // converters (model → editing view)
    conversion.for('editingDowncast').elementToElement({
      model: this.getSchemaModelName(),
      view: (modelItem: ModelElement, { writer: viewWriter }: { writer: ViewWriter }) => {
        const widgetElement = this.createViewElement(modelItem, viewWriter);

        // Enable widget handling on a placeholder element inside the editing view.
        return toWidget(widgetElement, viewWriter);
      },
    });

    if (this.getSchemaAttributes()?.length) {
      conversion.for('editingDowncast').add((dispatcher: ConverterDispatcher) =>
        dispatcher.on('attribute', (evt, data, conversionApi) => {
          if (!_.includes(this.getSchemaAttributes(), data.attributeKey)) return;
          const modelElement: ModelElement = data.item as ModelElement;
          conversionApi.consumable.consume(data.item, evt.name);
          const viewElement = conversionApi.mapper.toViewElement(modelElement);

          const domElement = this.editor.editing.view.domConverter.mapViewToDom(viewElement.getChild(0));
          if (!domElement) return;
          this.renderElement(modelElement, domElement);
        }),
      );
    }
  }

  createDataElement(modelItem: ModelElement, viewWriter: ViewWriter) {
    return viewWriter.createContainerElement(this.getDataElementName(), {
      class: this.getDataClasses(),
      ..._.reduce(
        this.getSchemaAttributes(),
        (acc, attribute) => ({
          ...acc,
          [attribute]: modelItem.getAttribute(attribute) || '',
        }),
        {},
      ),
    });
  }

  createViewElement(modelItem: ModelElement, viewWriter: ViewWriter) {
    const placeholderView = viewWriter.createContainerElement(this.getDataElementName(), {
      class: this.getDataClasses(),
    });
    // This element will host the React component for this plugin.
    const reactWrapper = viewWriter.createRawElement(
      'span',
      {
        class: 'reactWrapper',
      },
      (domElement) => {
        this.renderElement(modelItem, domElement);
      },
    );

    viewWriter.insert(viewWriter.createPositionAt(placeholderView, 0), reactWrapper);

    return placeholderView;
  }

  renderElement(modelItem: ModelElement, domElement: HTMLElement) {
    const editorConfiguration = this.editor.config;
    const deps: BasePluginDependencies = editorConfiguration.get(PluginDependencies.pluginName);

    const existingComponent = _.find(this.reactComponents, ({ element }) => element === domElement);
    if (existingComponent) {
      renderElementWithRoot(deps, domElement, this.getReactElement(modelItem), existingComponent.root);
    } else {
      const root = renderElementWithRoot(deps, domElement, this.getReactElement(modelItem));
      this.reactComponents.push({ element: domElement, root });
    }
  }

  destroy() {
    _.forEach(this.reactComponents, ({ root }) => root.unmount());
  }

  updateAttribute(modelItem: ModelElement, attribute: string) {
    return (value: string | number) =>
      this.editor.model.change((writer: ModelWriter) => {
        writer.setAttribute(attribute, value, modelItem);
      });
  }
}

export default InsertablePlugin;
