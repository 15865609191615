// @ts-strict-ignore
/* istanbul ignore file */
import { Plugin } from '@ckeditor/ckeditor5-core';
import { FileLoader, FileRepository } from '@ckeditor/ckeditor5-upload';
import { APPSERVER_API_CONTENT_TYPE, APPSERVER_API_PREFIX } from '@/main/app.constants';
import { BasePluginDependencies, CK_PRIORITY } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import DataTransfer from '@ckeditor/ckeditor5-clipboard/src/datatransfer';
import { isLocalImage as isBase64Image } from '@ckeditor/ckeditor5-image/src/imageupload/utils';
import { isNonContentImage, replacementImageUtils } from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { addCsrfHeader } from '@/utilities/auth.utilities';
import _ from 'lodash';
import { errorToast } from '@/utilities/toast.utilities';
import { getWorkbenchAddress } from '@/utilities/utilities';

export const ANNOTATIONS_PREFIX = `${APPSERVER_API_PREFIX}${SeeqNames.API.Annotations}`;
const IMAGE_CORS_PROXY = `${ANNOTATIONS_PREFIX}/image/cors`;

/**
 * This intercepts paste events,  dynamically uploading the image so that when the paste finishes the img src
 * points to an image on the Seeq server.
 */
export class SeeqImageUpload extends Plugin {
  init() {
    const editor = this.editor;
    const ckImageUploadCommand = editor.commands.get('imageUpload');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore return type definition for `CommandCollection` seems wrong
    ckImageUploadCommand.on(
      'execute',
      () => {
        SeeqImageUpload.setupUploadConfig(editor);
      },
      {
        // We cannot use the CKEditor defined priority levels here (i.e. priorities.highest), because this caused an
        // issue with some external image sources not being uploaded. There was an issue where the image was
        // attempted to be uploaded at the same time that the item's uploadId got set, so the uploadId was undefined.
        // Changing the priority to CK_PRIORITY.HIGH fixes this issue and makes sure that the uploadId gets set
        // before the image upload gets attempted.
        priority: CK_PRIORITY.HIGH,
      },
    );

    this.definePasteHandlers();
  }

  definePasteHandlers() {
    const editor = this.editor;

    const fileRepository = editor.plugins.get(FileRepository);
    editor.plugins.get('ClipboardPipeline').on(
      'inputTransformation',
      (evt: CkEvent, data: { dataTransfer: DataTransfer; content: DocumentFragment }) => {
        const elements = Array.from(editor.editing.view.createRangeIn(data.content));
        if (elements.length === 1 && data.dataTransfer.files.length === 1) {
          SeeqImageUpload.setupUploadConfig(editor);
          const loader = fileRepository.createLoader(data.dataTransfer.files[0]);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore type definition for `ViewElement` seems to be missing the `item` property
          elements[0].item._setAttribute('uploadId', loader.id);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore type definition for `ViewElement` seems to be missing the `item` property
          _.forEach(elements, ({ item }) => {
            if (!isBase64Image(replacementImageUtils, item) && isNonContentImage(item)) {
              item._removeAttribute('srcset');
              const config = SeeqImageUpload.setupUploadConfig(editor);
              const src = item.getAttribute('src');
              const url = _.includes(src, ANNOTATIONS_PREFIX) ? new URL(src, getWorkbenchAddress()).href : src;
              const promise = fetch(`${IMAGE_CORS_PROXY}/${encodeURIComponent(String(url))}`, {
                method: 'GET',
                headers: config.headers,
              })
                .then((response) => {
                  if (!response.ok) {
                    response
                      .clone()
                      .text()
                      .then((text) => {
                        errorToast({
                          messageKey: 'CORS_FAILED',
                          messageParams: { text },
                        });
                      });
                  }
                  return response;
                })
                .then((response) => response.blob())
                .then((blob) => new File([blob], `image.png`));
              const loader = fileRepository.createLoader(promise);
              item._setAttribute('uploadId', loader.id);
              item._setAttribute('src', '');
            } else if (isBase64Image(replacementImageUtils, item) && isNonContentImage(item)) {
              try {
                SeeqImageUpload.setupUploadConfig(editor);
                const src = item.getAttribute('src');
                const match = src.match(/data:(image\/\w+);base64/);
                const type = match ? match[1].toLowerCase() : 'image/jpeg';
                // Can't use the easier fetch() method because it violates the CSP
                const binaryData = atob(src.split(',')[1]);
                let n = binaryData.length;
                const blob = new Uint8Array(n);
                while (n--) {
                  blob[n] = binaryData.charCodeAt(n);
                }

                const loader = fileRepository.createLoader(new File([blob], type.replace('/', '.'), { type }));
                item._setAttribute('uploadId', loader.id);
                item._setAttribute('src', '');
              } catch (e) {
                errorToast({
                  messageKey: 'LICENSE.UPLOADED_ERROR',
                });
                throw e;
              }
            }
          });
        }
      },
      {
        priority: CK_PRIORITY.HIGH,
      },
    );
  }

  private static setupUploadConfig(editor: any): {
    headers: any;
    uploadUrl: string;
  } {
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);
    const id = deps.annotationId;

    const headers = {
      Accept: APPSERVER_API_CONTENT_TYPE,
    };
    addCsrfHeader(headers);
    const uploadConfig = {
      uploadUrl: `${ANNOTATIONS_PREFIX}/${id}/images`,
      headers,
    };
    editor.config.set('seeqUpload', uploadConfig);
    return uploadConfig;
  }
}

/**
 *  This is a modified version of SimpleUploadAdapter (see below link). The only difference is that the adapter
 *  options are inserted at Adapter creation instead of plugin generation.
 */
export class SeeqImageUploadAdapter extends Plugin {
  static get requires() {
    return [FileRepository];
  }

  static get pluginName() {
    return 'SeeqUploadAdapter';
  }

  init() {
    this.editor.plugins.get(FileRepository).createUploadAdapter = (loader: FileLoader) => {
      const options = this.editor.config.get('seeqUpload');
      return new Adapter(loader, options);
    };
  }
}

//region The below is mostly copied from the following source:
// https://github.com/ckeditor/ckeditor5/blob/8439f2989f6e2768d43c0a463f2284c22ae9b250/packages/ckeditor5-upload/src/adapters/simpleuploadadapter.js

class Adapter {
  loader: FileLoader;
  options;
  xhr: XMLHttpRequest;

  constructor(loader: FileLoader, options) {
    this.loader = loader;
    this.options = options;
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        }),
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    this.xhr = new XMLHttpRequest();
    const xhr = this.xhr;

    xhr.open('POST', this.options.uploadUrl, true);
    xhr.responseType = 'json';
  }

  _initListeners(resolve: (value?: unknown) => void, reject: (value?: unknown) => void, file: File) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}. Console may have more details.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || !response.link || response.error) {
        return reject(response && response.error && response.error.message ? response.error.message : genericErrorText);
      }

      resolve({ default: response.link });
    });

    // Upload progress when it is supported.
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file: File) {
    // Set headers if specified.
    const headers = this.options.headers || {};

    // Use the withCredentials flag if specified.
    const withCredentials = this.options.withCredentials || false;

    _.forEach(Object.keys(headers), (headerName) => this.xhr.setRequestHeader(headerName, headers[headerName]));

    this.xhr.withCredentials = withCredentials;

    // Prepare the form data.
    const data = new FormData();

    data.append('file', file);

    // Send the request.
    this.xhr.send(data);
  }
}

//endregion
