// @ts-strict-ignore
/* istanbul ignore file */
import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { Table } from '@ckeditor/ckeditor5-table';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-table-row.svg';
import i18next from 'i18next';

export const STRIPED_ATTRIBUTE = 'seeqTableStriped';
export const STRIPED_COMMAND = 'toggleTableStripes';

export class StripedTables extends Plugin {
  static get requires() {
    return [Table];
  }

  static pluginName = 'StripedTables';

  init() {
    this.editor.commands.add(STRIPED_COMMAND, new (StripedTablesCommand as any)(this.editor));

    this.extendSchema();
    this.defineToolbarButton();
    this.defineConverters();
  }

  extendSchema() {
    const editor = this.editor;
    editor.model.schema.extend('table', { allowAttributes: STRIPED_ATTRIBUTE });
  }

  defineToolbarButton() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    editor.ui.componentFactory.add(StripedTables.pluginName, (locale) => {
      const command = editor.commands.get(STRIPED_COMMAND);
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: i18next.t('REPORT.EDITOR.STRIPING'),
        tooltip: true,
        icon,
        tooltipPosition: 's',
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => editor.execute(STRIPED_COMMAND));

      return buttonView;
    });
  }

  defineConverters() {
    const editor = this.editor;

    // Dedicated converter to propagate image's attribute to the img tag.
    editor.conversion.for('downcast').add((dispatcher) =>
      dispatcher.on(`attribute:${STRIPED_ATTRIBUTE}:table`, (event, data, conversionApi) => {
        if (!conversionApi.consumable.consume(data.item, event.name)) {
          return;
        }

        const viewWriter = conversionApi.writer as ViewWriter;
        const figure = conversionApi.mapper.toViewElement(data.item as ModelElement);

        if (data.attributeNewValue !== null) {
          viewWriter.addClass(STRIPED_ATTRIBUTE, figure);
        } else {
          viewWriter.removeClass(STRIPED_ATTRIBUTE, figure);
        }
      }),
    );

    editor.conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'figure',
        key: 'class',
        value: 'seeqTableStriped',
      },
      model: {
        key: STRIPED_ATTRIBUTE,
        value: () => true,
      },
    });
  }
}

class StripedTablesCommand extends Command {
  execute() {
    const editor = this.editor;
    const model = editor.model;
    const selection = model.document.selection;

    const table = selection.getFirstPosition().findAncestor('table');

    model.change((writer) => {
      if (table) {
        table.getAttribute(STRIPED_ATTRIBUTE)
          ? writer.removeAttribute(STRIPED_ATTRIBUTE, table)
          : writer.setAttribute(STRIPED_ATTRIBUTE, true, table);
      }
    });
  }
}
