// @ts-strict-ignore
/* istanbul ignore file */
import React from 'react';
import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import ArrowlessDropdownButton from '@/annotation/ckEditorPlugins/views/ArrowlessDropdownButton';
import {
  BasePluginDependencies,
  CK_DROPDOWN_BUTTON_CLASS,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-link.svg';
import { renderElementWithRoot, toggleCkResetClass } from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { JournalLink as JournalLinkMolecule } from '@/annotation/JournalLink.molecule';
import i18next from 'i18next';
import { JOURNAL_PREFIX_PATH } from '@/main/app.constants';
import { isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { executeLinkAction } from '@/utilities/journalLink.utilities';
import { LinkEditing } from '@ckeditor/ckeditor5-link';
import LinkUI from '@ckeditor/ckeditor5-link/src/linkui';

export const COMMAND_NAME = 'journal_link';

function executeJournalLinkClickListener(event: MouseEvent & { target: HTMLElement }) {
  event.preventDefault();
  const aTag = event.target.closest('a');
  const urlParams = new URLSearchParams(aTag.href.substring(aTag.href.indexOf('?')));
  executeLinkAction(urlParams);
}

export class JournalLink extends Plugin {
  static get requires() {
    return [LinkEditing];
  }

  static pluginName = 'JournalLink';
  static setup = {
    name: JournalLink.pluginName,
    plugin: JournalLink,
    toolbar: JournalLink.pluginName,
  };

  init() {
    this.defineToolbarButton();
    this.editor.commands.add(COMMAND_NAME, new JournalLinkCommand(this.editor));
    this.setupJournalLinkListener();
    this.setupJournalLinkEditingPopover();
  }

  /**
   * We intercept the balloon view that shows the link information to inject our own button. Approach slightly
   * modified from https://github.com/ckeditor/ckeditor5/issues/4836#issuecomment-1422740830
   */
  setupJournalLinkEditingPopover() {
    const editor = this.editor;
    const linkUI = editor.plugins.get('LinkUI');
    const contextualBalloonPlugin = editor.plugins.get('ContextualBalloon');
    let button = null;
    let actionsView = null;
    let originalTooltip = '';

    (this as any).listenTo(contextualBalloonPlugin, 'change:visibleView', (evt, name, visibleView) => {
      // Always remove the button so if we re-add it it gets associated with the most recently clicked on journal link
      // and also so it doesn't show up for normal links that are clicked after the journal link. This is because
      // the actions view is a singleton whose listeners are the only things that normally get updated when clicking
      // on different links.
      if (button) {
        actionsView.deregisterChild(button);
        actionsView.previewButtonView.element.removeEventListener('click', executeJournalLinkClickListener);
        actionsView.previewButtonView.set({ tooltip: originalTooltip });
        button.element.remove();
        actionsView = null;
        button = null;
      }

      if (visibleView === linkUI.actionsView && visibleView.href?.indexOf(JOURNAL_PREFIX_PATH) === 0) {
        actionsView = linkUI.actionsView;
        button = this.makeEditLinkButton();
        // Render button's template.
        button.render();

        // Register the button under the link form view, it will handle its destruction.
        actionsView.registerChild(button);

        // Inject the element into DOM.
        actionsView.element.insertBefore(button.element, actionsView.editButtonView.element);

        // Since the a tag in the popover is outside of CK's model, we can't and shouldn't rely on it's listener
        actionsView.previewButtonView.element.addEventListener('click', executeJournalLinkClickListener);

        originalTooltip = actionsView.previewButtonView.tooltip;
        actionsView.previewButtonView.set({ tooltip: '' });
      }
    });
  }

  makeEditLinkButton() {
    const editor = this.editor;
    const linkCommand = editor.commands.get('link');

    const button = this.makeDropdownJournalButton(
      editor,
      'REPORT.EDITOR.CONTENT_BUTTON_TITLE_UPDATE',
      (editor, dropdown, link) => {
        editor.execute(COMMAND_NAME, link, true);
        dropdown.panelView.set('isVisible', false);
      },
    );

    // Probably this button should be also disabled when the link command is disabled.
    // Try setting editor.isReadOnly = true to see it in action.
    button.bind('isEnabled').to(linkCommand);

    return button;
  }

  defineToolbarButton() {
    const editor = this.editor;
    editor.ui.componentFactory.add(JournalLink.pluginName, () =>
      this.makeDropdownJournalButton(editor, 'REPORT.EDITOR.CONTENT_BUTTON_TITLE', (editor, dropdown, link) => {
        editor.execute(COMMAND_NAME, link);
        dropdown.panelView.set('isVisible', false);
      }),
    );
  }

  makeDropdownJournalButton(editor: any, buttonTooltip: string, onLink: (editor, dropdown, link) => void) {
    const dropdown = createDropdown(editor.locale, ArrowlessDropdownButton);

    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    dropdown.buttonView.set({
      label: i18next.t(buttonTooltip),
      icon,
      tooltipPosition: 'se',
      tooltip: true,
      class: 'contentBtn ck-reset_all-excluded',
    });

    dropdown.panelView.on('render', (eventInfo) => {
      renderElementWithRoot(
        deps,
        eventInfo.source.element,
        <div className="ck-reset_all-excluded">
          <JournalLinkMolecule onLink={(link) => onLink(editor, dropdown, link)} />
        </div>,
      );
    });

    dropdown.panelView.on('change:isVisible', () => toggleCkResetClass());

    dropdown.class = CK_DROPDOWN_BUTTON_CLASS;

    return dropdown;
  }

  setupJournalLinkListener() {
    const editor = this.editor;
    editor.plugins.get(LinkEditing)?.listenTo(
      editor.editing.view.document,
      'click',
      (ckEvent, domEvent) => {
        let clickedElement = domEvent.domTarget;
        if (clickedElement.tagName.toLowerCase() !== 'a') {
          clickedElement = clickedElement.closest('a');
        }

        if (!clickedElement) {
          return;
        }
        const attrValue: string = clickedElement.getAttribute('href');

        const linkCommand = editor.commands.get('link');

        if (
          attrValue?.indexOf(JOURNAL_PREFIX_PATH) === 0 &&
          (isViewOnlyWorkbookMode() || domEvent.domEvent.ctrlKey || domEvent.domEvent.metaKey)
        ) {
          const urlParams = new URLSearchParams(attrValue.substring(attrValue.indexOf('?')));
          executeLinkAction(urlParams);
          // prevent default panel being shown
          ckEvent.stop();
          // CRAB-33815: Stop browser event when in view mode
          domEvent.preventDefault();
        }
      },
      { priority: 'highest', context: '$capture' },
    );
  }
}

export class JournalLinkCommand extends Command {
  /**
   * Handles inserting the link into CK editor
   *
   * @param link - The link to insert
   * @param forceExecuteLinkCommand - If true, will skip determining the optimal location to insert the link and
   * will instead pass the link to CK's `link` command.
   */
  execute(link = '', forceExecuteLinkCommand = false) {
    const editor = this.editor;
    if (editor.model.document.selection.isCollapsed && !forceExecuteLinkCommand) {
      const viewFragment = editor.data.processor.toView(link);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(modelFragment);
    } else {
      // This code path is taken when highlighting text and inserting a new journal link or when editing an existing
      // journal link. This will either convert highlighted text to an a tag, or replace the href in a selected a tag
      editor.execute('link', link.match('href="(.*)">')[1]);
    }
  }
}
