// @ts-strict-ignore
import React from 'react';
import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { renderElementWithRoot } from '../CKEditorPlugins.utilities';
import { BasePluginDependencies } from '../CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import _ from 'lodash';
import { Root } from 'react-dom/client';
import { IframeContent } from '../components/IframeContent.atom';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-iota.svg';
import { IFRAME_CONTENT_ATTRIBUTES } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import i18next from 'i18next';

export const SCHEMA_MODEL_NAME = 'iframe_content';
export const COMMAND_NAME = 'iframe_content';
export const CLASSES = 'customCkComponent iframeContent inlineBlock';
export const DATA_IFRAME_CONTENT_URL = IFRAME_CONTENT_ATTRIBUTES.DATA_IFRAME_CONTENT_URL;
export const DATA_IFRAME_CONTENT_WIDTH = IFRAME_CONTENT_ATTRIBUTES.DATA_IFRAME_CONTENT_WIDTH;
export const DATA_IFRAME_CONTENT_HEIGHT = IFRAME_CONTENT_ATTRIBUTES.DATA_IFRAME_CONTENT_HEIGHT;

/**
 * Plugin that enables the use of DateRangeLabel components inside the CKEditor.
 * The DateRangeLabel is a component that allows displaying information in real-time
 * about a specific DateRange (start time, end time). This can be used for labeling
 * a group of content pieces that use the same date range. Once the date range changes
 * all the content pieces and the label will update.
 *
 * This plugin adds the date_range_label command to CKEditor
 */
export class IframeContentPlugin extends Plugin {
  private reactDomRoots: Root[] = [];

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineToolbar();

    // add command
    this.editor.commands.add(COMMAND_NAME, new IframeContentCommand(this.editor));
    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement: ViewElement) =>
        viewElement.hasClass('iframeContent'),
      ),
    );
  }

  destroy() {
    _.forEach(this.reactDomRoots, (root) => {
      root && root.unmount();
    });
  }

  defineSchema() {
    const schema = this.editor.model.schema;
    schema.register(SCHEMA_MODEL_NAME, {
      allowWhere: '$text',
      isInline: true,
      isObject: true,
      allowAttributesOf: '$text',
      allowAttributes: [DATA_IFRAME_CONTENT_URL, DATA_IFRAME_CONTENT_WIDTH, DATA_IFRAME_CONTENT_HEIGHT],
    });
  }

  defineConverters() {
    const conversion = this.editor.conversion;
    // converters ((data) view → model)
    conversion.for('upcast').elementToElement({
      view: {
        name: 'div',
        attributes: {
          [DATA_IFRAME_CONTENT_URL]: true,
          [DATA_IFRAME_CONTENT_WIDTH]: true,
          [DATA_IFRAME_CONTENT_HEIGHT]: true,
        },
      },
      model: (viewElement, { writer: modelWriter }) => {
        return modelWriter.createElement(SCHEMA_MODEL_NAME, {
          [DATA_IFRAME_CONTENT_URL]: viewElement.getAttribute(DATA_IFRAME_CONTENT_URL),
          [DATA_IFRAME_CONTENT_WIDTH]: viewElement.getAttribute(DATA_IFRAME_CONTENT_WIDTH),
          [DATA_IFRAME_CONTENT_HEIGHT]: viewElement.getAttribute(DATA_IFRAME_CONTENT_HEIGHT),
        });
      },
      converterPriority: 'highest',
    });

    // converters (model → data view)
    conversion.for('dataDowncast').elementToElement({
      model: SCHEMA_MODEL_NAME,
      view: (modelItem, { writer: viewWriter }) => this.createDataElement(modelItem, viewWriter),
    });

    // converters (model → editing view)
    conversion.for('editingDowncast').elementToElement({
      model: SCHEMA_MODEL_NAME,
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = this.createViewElement(modelItem, viewWriter);

        // Enable widget handling on a placeholder element inside the editing view.
        return toWidget(widgetElement, viewWriter);
      },
    });
    conversion.for('editingDowncast').add((dispatcher) =>
      dispatcher.on('attribute', (evt, data, conversionApi) => {
        if (
          !_.includes(
            [DATA_IFRAME_CONTENT_URL, DATA_IFRAME_CONTENT_WIDTH, DATA_IFRAME_CONTENT_HEIGHT],
            data.attributeKey,
          )
        )
          return;
        const modelElement: ModelElement = data.item as ModelElement;
        conversionApi.consumable.consume(data.item, evt.name);
        const viewElement = conversionApi.mapper.toViewElement(modelElement);

        const domElement = this.editor.editing.view.domConverter.mapViewToDom(viewElement.getChild(0));
        if (!domElement) return;
        this.renderElement(modelElement, domElement);
      }),
    );
  }

  defineToolbar() {
    const editor = this.editor;
    editor.ui.componentFactory.add('IframeContent', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: i18next.t('REPORT.EDITOR.IFRAME_CONTENT'),
        icon,
        tooltip: true,
      });
      view.on('execute', () => {
        editor.execute(COMMAND_NAME);
      });
      return view;
    });
  }

  createDataElement(modelItem: ModelElement, viewWriter: ViewWriter) {
    const placeholderView = viewWriter.createContainerElement('div', {
      [DATA_IFRAME_CONTENT_URL]: modelItem.getAttribute(DATA_IFRAME_CONTENT_URL) || '',
      [DATA_IFRAME_CONTENT_WIDTH]: modelItem.getAttribute(DATA_IFRAME_CONTENT_WIDTH) || '',
      [DATA_IFRAME_CONTENT_HEIGHT]: modelItem.getAttribute(DATA_IFRAME_CONTENT_HEIGHT) || '',
      class: CLASSES,
    });
    return placeholderView;
  }

  createViewElement(modelItem: ModelElement, viewWriter: ViewWriter) {
    const placeholderView = viewWriter.createContainerElement('div', {
      class: CLASSES,
    });
    // This element will host a React <Content /> component.
    const reactWrapper = viewWriter.createRawElement(
      'div',
      {
        class: 'reactWrapper',
      },
      (domElement) => {
        this.renderElement(modelItem, domElement);
      },
    );

    viewWriter.insert(viewWriter.createPositionAt(placeholderView, 0), reactWrapper);

    return placeholderView;
  }

  renderElement(modelItem: ModelElement, domElement: HTMLElement) {
    const editorConfiguration = this.editor.config;
    const deps: BasePluginDependencies = editorConfiguration.get(PluginDependencies.pluginName);
    const iframeContentUrl = modelItem.getAttribute(DATA_IFRAME_CONTENT_URL);
    const iframeContentWidth = modelItem.getAttribute(DATA_IFRAME_CONTENT_WIDTH);
    const iframeContentHeight = modelItem.getAttribute(DATA_IFRAME_CONTENT_HEIGHT);

    const root = renderElementWithRoot(
      deps,
      domElement,
      <IframeContent
        url={iframeContentUrl}
        width={iframeContentWidth}
        height={iframeContentHeight}
        viewMode={!deps.canModify}
        updateUrl={this.updateAttribute(modelItem, DATA_IFRAME_CONTENT_URL)}
        updateWidth={this.updateAttribute(modelItem, DATA_IFRAME_CONTENT_WIDTH)}
        updateHeight={this.updateAttribute(modelItem, DATA_IFRAME_CONTENT_HEIGHT)}
      />,
    );
    this.reactDomRoots.push(root);
  }

  updateAttribute(modelItem: ModelElement, attribute: string) {
    return (value: string | number) =>
      this.editor.model.change((writer) => {
        writer.setAttribute(attribute, value, modelItem);
      });
  }
}

export class IframeContentCommand extends Command {
  execute() {
    const editor = this.editor;
    editor.model.change((writer) => {
      // Create a <placeholder> elment with the "name" attribute (and all the selection attributes)...
      const element = writer.createElement(SCHEMA_MODEL_NAME, {
        [DATA_IFRAME_CONTENT_URL]: '',
        [DATA_IFRAME_CONTENT_WIDTH]: 700,
        [DATA_IFRAME_CONTENT_HEIGHT]: 500,
      });

      // ... and insert it into the document.
      editor.model.insertObject(element);

      // Put the selection on the inserted element.
      writer.setSelection(element, 'on');
      // we need to focus back on the editor after a short delay
      editor.editing.view.focus();
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;

    const isAllowed = model.schema.checkChild(selection.focus.parent, SCHEMA_MODEL_NAME);
    this.isEnabled = isAllowed;
  }
}
