export enum Visualization {
  TABLE = 'table',
  XY_PLOT = 'xyPlot',
  TREND = 'trend',
  NONE = 'none',
}

export type VisualizationData = {
  visualization: Visualization;
  [s: string]: any;
};

export const URL_LOADED_BUT_NOT_CHART = 'DATA_LOADED_BUT_NOT_CHART';
export type LoadingState = boolean | typeof URL_LOADED_BUT_NOT_CHART;
