import { Command, Plugin } from '@ckeditor/ckeditor5-core';

export const TEMPLATE_COMMAND = 'template';

/**
 * Plugin that enables the use of Template components inside the CKEditor.
 * The Template is some html that can be inserted anywhere into the Topic.
 * This can be useful for quickly building Topics.
 *
 * This plugin adds the template command to CKEditor
 */
export class TemplatePlugin extends Plugin {
  init() {
    this.editor.commands.add(TEMPLATE_COMMAND, new TemplateCommand(this.editor));
  }
}

export class TemplateCommand extends Command {
  execute(templateObject: { templateHtml: string }, dataTransfer = new DataTransfer()) {
    dataTransfer.setData('text/html', templateObject.templateHtml);
    this.editor.editing.view.document.fire('clipboardInput', {
      dataTransfer,
      content: dataTransfer,
    });
  }
}
