// @ts-strict-ignore
import React from 'react';
import { BasePluginDependencies, DATE_RANGE_LABEL_ATTRIBUTES } from '../CKEditorPlugins.constants';
import InsertablePlugin from '@/annotation/ckEditorPlugins/plugins/InsertablePlugin';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { EditDateRangeLabel } from '@/annotation/ckEditorPlugins/components/EditDateRangeLabel.molecule';
import { Command } from '@ckeditor/ckeditor5-core';

const SCHEMA_MODEL_NAME = 'date_range_label';
const UNIQUE_CLASS = 'dateRangeLabel';
const CLASSES = `customCkComponent ${UNIQUE_CLASS}`;
const DATA_DATE_RANGE_ID = DATE_RANGE_LABEL_ATTRIBUTES.DATA_DATE_RANGE_ID;
const DATA_DATE_RANGE_FORMAT = DATE_RANGE_LABEL_ATTRIBUTES.DATA_DATE_RANGE_FORMAT;
const DATA_DATE_RANGE_CONTENT = DATE_RANGE_LABEL_ATTRIBUTES.DATA_DATE_RANGE_CONTENT;

/**
 * Plugin that enables the use of DateRangeLabel components inside the CKEditor.
 * The DateRangeLabel is a component that allows displaying information in real-time
 * about a specific DateRange (start time, end time). This can be used for labeling
 * a group of content pieces that use the same date range. Once the date range changes
 * all the content pieces and the label will update.
 *
 * This plugin adds the date_range_label command to CKEditor
 */
export class DateRangeLabelPlugin extends InsertablePlugin {
  getSchemaModelName(): string {
    return SCHEMA_MODEL_NAME;
  }

  getSchemaAttributes(): string[] | undefined {
    return [DATA_DATE_RANGE_ID, DATA_DATE_RANGE_FORMAT, DATA_DATE_RANGE_CONTENT];
  }

  getDataElementName(): string {
    return 'span';
  }

  getDataClasses(): string {
    return CLASSES;
  }

  getReactElement(modelElement: ModelElement): React.ReactElement {
    const editorConfiguration = this.editor.config;
    const deps: BasePluginDependencies = editorConfiguration.get(PluginDependencies.pluginName);
    const dateRangeId = modelElement.getAttribute(DATA_DATE_RANGE_ID);
    const dateRangeFormat = modelElement.getAttribute(DATA_DATE_RANGE_FORMAT);
    const dateRangeContent = modelElement.getAttribute(DATA_DATE_RANGE_CONTENT);

    return (
      <EditDateRangeLabel
        dateRangeId={dateRangeId}
        dateRangeFormat={dateRangeFormat}
        dateRangeContent={dateRangeContent}
        viewMode={!deps.canModify}
        updateId={this.updateAttribute(modelElement, DATA_DATE_RANGE_ID)}
        updateContent={this.updateAttribute(modelElement, DATA_DATE_RANGE_CONTENT)}
        updateFormat={this.updateAttribute(modelElement, DATA_DATE_RANGE_FORMAT)}
      />
    );
  }

  getUniqueClass(): string {
    return UNIQUE_CLASS;
  }

  postInit(): void {
    this.editor.commands.add('date_range_label', new DateRangeLabelCommand(this.editor));
  }
}

export class DateRangeLabelCommand extends Command {
  execute() {
    const editor = this.editor;
    editor.model.change((writer) => {
      if (editor.model.document.selection.getSelectedElement()) {
        writer.setSelection(editor.model.document.selection.getSelectedElement(), 'after');
      }
      // Create a <placeholder> element with the "name" attribute (and all the selection attributes)...
      const element = writer.createElement(SCHEMA_MODEL_NAME, []);

      // ... and insert it into the document.
      editor.model.insertObject(element);

      // Put the selection on the inserted element.
      writer.setSelection(element, 'on');
      // we need to focus back on the editor after a short delay
      editor.editing.view.focus();
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;

    const isAllowed = model.schema.checkChild(selection.focus.parent, SCHEMA_MODEL_NAME);
    this.isEnabled = isAllowed;
  }
}
