// @ts-strict-ignore
/* istanbul ignore file */
import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { Image } from '@ckeditor/ckeditor5-image';
import i18next from 'i18next';
import { IMAGE_BORDER_CLASS } from '@/reportEditor/report.constants';

const BORDER_ATTRIBUTE = 'border';
export const BORDER_COMMAND = 'toggleSeeqBorder';

/**
 * This adds a button which when pressed toggles a border around the selected image
 */
export class SeeqImageBorder extends Plugin {
  static get requires() {
    return [Image];
  }

  static pluginName = 'SeeqImageBorder';

  init() {
    this.editor.commands.add(BORDER_COMMAND, new (SeeqBorderCommand as any)(this.editor));

    this.extendSchema();
    this.defineToolbarButton();
    this.defineConverters();
  }

  extendSchema() {
    const editor = this.editor;
    editor.model.schema.extend('imageInline', {
      allowAttributes: BORDER_ATTRIBUTE,
    });
    editor.model.schema.extend('imageBlock', {
      allowAttributes: BORDER_ATTRIBUTE,
    });
  }

  defineToolbarButton() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    editor.ui.componentFactory.add(SeeqImageBorder.pluginName, (locale) => {
      const command = editor.commands.get(BORDER_COMMAND);
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: i18next.t('REPORT.EDITOR.BORDER'),
        withText: true,
        tooltip: true,
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => editor.execute(BORDER_COMMAND));

      return buttonView;
    });
  }

  defineConverters() {
    const editor = this.editor;

    const borderDowncast = (event: CkEvent, data: ConversionData, conversionApi: ConversionApi) => {
      if (!conversionApi.consumable.consume(data.item, event.name)) {
        return;
      }

      const viewWriter = conversionApi.writer as ViewWriter;
      const img = conversionApi.mapper.toViewElement(data.item as ModelElement);

      if (data.attributeNewValue !== null) {
        viewWriter.addClass(IMAGE_BORDER_CLASS, img);
      } else {
        viewWriter.removeClass(IMAGE_BORDER_CLASS, img);
      }
    };

    // Dedicated converter to propagate image's attribute to the img tag.
    editor.conversion
      .for('downcast')
      .add((dispatcher) => dispatcher.on('attribute:border:imageInline', borderDowncast));
    editor.conversion.for('downcast').add((dispatcher) => dispatcher.on('attribute:border:imageBlock', borderDowncast));

    // Block images
    editor.conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'figure',
        key: 'class',
        value: IMAGE_BORDER_CLASS,
      },
      model: {
        key: BORDER_ATTRIBUTE,
        value: () => true,
      },
    });

    // Inline Images
    editor.conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'img',
        key: 'class',
        value: IMAGE_BORDER_CLASS,
      },
      model: {
        key: BORDER_ATTRIBUTE,
        value: () => true,
      },
    });
  }
}

class SeeqBorderCommand extends Command {
  refresh() {
    const element = this.editor.model.document.selection.getSelectedElement();

    this.isEnabled = canHaveBorder(element);

    if (canHaveBorder(element) && element.hasAttribute(BORDER_ATTRIBUTE)) {
      this.value = element.getAttribute(BORDER_ATTRIBUTE);
    } else {
      this.value = false;
    }
  }

  execute() {
    const model = this.editor.model;
    const imageElement = model.document.selection.getSelectedElement();

    model.change((writer) => {
      imageElement.getAttribute(BORDER_ATTRIBUTE)
        ? writer.removeAttribute(BORDER_ATTRIBUTE, imageElement)
        : writer.setAttribute(BORDER_ATTRIBUTE, true, imageElement);
    });
  }
}

function canHaveBorder(modelElement: any) {
  return modelElement && (modelElement.is('element', 'imageInline') || modelElement.is('element', 'imageBlock'));
}
